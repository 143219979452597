import { template as template_de2933833dcc4a65a26d3956dbcb0324 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_de2933833dcc4a65a26d3956dbcb0324(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
