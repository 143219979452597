import { template as template_e86a47f8eec84d769c18255a8cf51762 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e86a47f8eec84d769c18255a8cf51762(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
