import { template as template_73c769fa235047b3b55af8ae5b9df349 } from "@ember/template-compiler";
const FKLabel = template_73c769fa235047b3b55af8ae5b9df349(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
