import { template as template_da4bbf1f274147c59aaea70a3f9963dc } from "@ember/template-compiler";
const FKText = template_da4bbf1f274147c59aaea70a3f9963dc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
